import { useApiGuestInstance } from './apiGuestInstance';

const CACHE_TTL = 60 * 15; // 15 min
const cache = new Map();

interface CacheEntry {
  data: any;
  timestamp: number;
}

export const apiGuestInstanceWithCache = async (url: string, options?: any): Promise<any> => {
  const cacheKey = `${url}${options?.params ? JSON.stringify(options.params) : ''}`;

  const cached = cache.get(cacheKey) as CacheEntry | undefined;
  if (cached && Date.now() - cached.timestamp < CACHE_TTL * 1000) {
    return cached.data;
  }

  const response = await useApiGuestInstance(url, options);

  cache.set(cacheKey, {
    data: response,
    timestamp: Date.now(),
  });

  return response;
};
