import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { useAdditionParams } from '@skeleton/core/assets/useAdditionParams';

const LOG_TIMEOUT = 2000;

export const useApiGuestInstance = async (url: string, options?: any): Promise<any> => {
  const baseURL = import.meta.server && process.env.API_BASE_URL ? process.env.API_BASE_URL : '';
  const traceId = uuidv4();

  const time = Date.now();

  const addParams = useAdditionParams();

  const { source, platform } = addParams.getRequestParams(url);

  let newUrl = url;

  if (options?.params) {
    const stringifyParams = queryString.stringify(options.params, { arrayFormat: 'bracket' });
    newUrl = `${url}?${stringifyParams}`;
  }

  const baseHeaders = {
    'x-trace-id': traceId,
    ...(source && { source }),
    ...(platform && { platform }),
  };

  const newOptions = {
    ...options,
    baseURL,
    params: undefined,
    credentials: 'omit',
    retry: 0,
    maxResponseSize: 512 * 1024,
    headers: { ...baseHeaders, ...options?.headers },
    timeout: 10000,
    async onResponse() {
      const timeOut = Date.now() - time;
      console.log(`${url}, ${traceId}, ${timeOut}ms`);
    },
  };

  try {
    const response = await $fetch(newUrl, newOptions);
    newOptions.onResponse = undefined;
    return response;
  } catch (e: any) {
    if (!e.response) {
      console.warn(`No response: ${url}\n`, traceId);
    }
    throw e;
  }
};
