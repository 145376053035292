import { defineStore } from 'pinia';

import { sportsList, BannerEventBoxDefault } from '@skeleton/core/consts/sportsConfig';
const MOBILE = 1;
const DESKTOP = 2;

interface IStartParams {
  accountId?: string;
  lobbyUrl: string;
  locale: string;
  countryCode?: string;
  demoMode: boolean;
  platform: number;
  provider: string;
}

interface IAltenarStoreState {
  startParams: Maybe<IStartParams>;
  altenarToken: string;
  widgetSportBookInstance: any;
  widgetWTopEventsCarouselInstance: any;
  widgetWLiveSportCarouselInstance: any;
  isAltenarInitiated: boolean;
}

export interface IWidgetParams {
  page?: Maybe<string>;
  championshipIds?: number[];
  categoryIds?: number[];
  sportId?: number;
}

export const useAltenarStore = defineStore('altenarStore', {
  state: (): IAltenarStoreState => ({
    startParams: null,
    altenarToken: '',
    widgetSportBookInstance: null,
    widgetWTopEventsCarouselInstance: null,
    widgetWLiveSportCarouselInstance: null,
    isAltenarInitiated: false,
  }),

  getters: {
    sportsIdsBgs(): Maybe<{ [key: number]: string }> {
      const {
        public: { gamehubCdn },
      } = useRuntimeConfig();

      return sportsList.reduce(function (acc: { [key: number]: string }, sport) {
        if (sport.widgetBgUrl) {
          acc[sport.id] = gamehubCdn + sport.widgetBgUrl;
        }
        return acc;
      }, {});
    },
  },

  actions: {
    setStartParams() {
      const { activeAccount } = useWalletStore();
      const { currentLocale, headerCountry } = useGlobalStore();
      const { profile } = useProfileStore();

      const deviceStore = useDeviceStore();
      const { isMobile } = storeToRefs(deviceStore);

      this.startParams = {
        accountId: activeAccount?.id,
        lobbyUrl: window.location.origin,
        locale: currentLocale?.code || 'en',
        countryCode: profile?.country || headerCountry || 'UA',
        demoMode: false,
        platform: isMobile.value ? MOBILE : DESKTOP,
        provider: 'altenar',
      };
    },

    async getAltenarToken(): Promise<void> {
      const { profile } = useProfileStore();
      if (!profile) return;

      try {
        const { getStartGame } = useCoreGamesApi();
        const startResponse = await getStartGame('altenar-sportsbook-altenar-sportsbook', this.startParams);
        if (startResponse.token) {
          this.altenarToken = startResponse.token;
        }
      } catch (e) {
        console.log('Error Altenar Token:', e);
      }
    },

    async initAltenarScript(): Promise<void> {
      this.setStartParams();
      await this.getAltenarToken();

      if (window.altenarWSDK) {
        this.initAltenarWSDK();
      } else {
        const { addAltenarScript } = useProjectMethods();
        const altenarScript = addAltenarScript();
        altenarScript.onload = () => {
          this.initAltenarWSDK();
        };
      }
    },

    initAltenarWSDK(): void {
      const runtimeConfig = useRuntimeConfig();
      const { currentLocale } = useGlobalStore();
      const { getColorMode } = useColorModeLogic();

      window.altenarWSDK.init({
        integration: runtimeConfig.public.altenarIntegration,
        culture: currentLocale?.localeCode
          ? currentLocale?.localeCode.replace('es-MX', 'es-ES').replace('en-US', 'en-GB')
          : 'en-GB',
        token: this.altenarToken,
        themeName: getColorMode(),
      });
      this.isAltenarInitiated = true;

      useEvent('altenarWSDKInit');
    },

    waitForAltenarWSDKInit(): Promise<boolean> {
      return new Promise(resolve => {
        if (this.isAltenarInitiated) {
          resolve(true);
        }

        useListen('altenarWSDKInit', payload => {
          resolve(payload);
        });
      });
    },

    async updateAltenarToken(): Promise<void> {
      await this.getAltenarToken();
      const { getColorMode } = useColorModeLogic();

      window.altenarWSDK.set({
        token: this.altenarToken,
        themeName: getColorMode(),
      });
    },

    async addSportBookWidget(containerId: string, widgetParams?: Maybe<IWidgetParams>): Promise<boolean> {
      await this.waitForAltenarWSDKInit();

      return new Promise(resolve => {
        this.widgetSportBookInstance = window.altenarWSDK.addSportsBook({
          props: {
            ...widgetParams,
            onRouterLoad: () => {
              resolve(true);
            },
          },
          container: document.getElementById(containerId),
        });
      });
    },

    async removeSportBookWidget(): Promise<void> {
      if (this.widgetSportBookInstance !== null) {
        await this.widgetSportBookInstance.remove();
      }

      this.widgetSportBookInstance = null;
    },

    async updateSportBookWidget(widgetParams?: Maybe<IWidgetParams>): Promise<void> {
      if (widgetParams) {
        window.altenarWSDK.set(widgetParams);
      }
    },

    async removeTopEventsCarouselWidget(): Promise<void> {
      await this.widgetWTopEventsCarouselInstance?.remove();
    },

    async addTopEventsCarouselWidget(containerId: string, sportId?: number): Promise<void> {
      await this.waitForAltenarWSDKInit();

      this.widgetWTopEventsCarouselInstance = window.altenarWSDK.addWidget({
        widget: 'WTopEventsCarousel',
        tokens: {
          BannerEventBoxDefault,
        },
        props: {
          backgroundOverride: {
            sport: this.sportsIdsBgs,
          },
          imageType: 2,
          eventCount: 7,
          onEventSelect: async event => {
            await navigateTo(`/sport#/event/${event.event.id}`);
          },
          onOddSelect: async odd => {
            await navigateTo(`/sport#/event/${odd.eventId}`);
          },
          sportId: sportId ?? undefined,
        },
        container: document.getElementById(containerId),
      });
    },

    async updateTopEventsCarouselWidget(sportId?: number): Promise<void> {
      this.widgetWTopEventsCarouselInstance?.update({ sportId });
    },

    async removeLiveSportCarouselWidget(): Promise<void> {
      await this.widgetWLiveSportCarouselInstance.remove();
    },

    async addLiveSportCarouselWidget(containerId: string, sportId?: number): Promise<void> {
      await this.waitForAltenarWSDKInit();

      this.widgetWLiveSportCarouselInstance = window.altenarWSDK.addWidget({
        widget: 'WLiveNow',
        tokens: {
          BannerEventBoxDefault,
        },
        props: {
          backgroundOverride: {
            sport: this.sportsIdsBgs,
          },
          eventCount: 7,
          onEventSelect: async event => {
            await navigateTo(`/sport#/event/${event.event.id}`);
          },
          onOddSelect: async odd => {
            await navigateTo(`/sport#/event/${odd.eventId}`);
          },
          sportId: sportId ?? undefined,
        },
        container: document.getElementById(containerId),
      });
    },

    async updateLiveSportCarouselWidget(containerId: string, sportId?: number): Promise<void> {
      await this.removeLiveSportCarouselWidget();
      await this.addLiveSportCarouselWidget(containerId, sportId);
    },
  },
});
