import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { useAdditionParams } from '@skeleton/core/assets/useAdditionParams';

const LOG_TIMEOUT = 2000;

export const useApiAuthInstance = async (url: string, options?: any): Promise<any> => {
  const profileStore = useProfileStore();
  const traceId = uuidv4();

  const addParams = useAdditionParams();

  const { source, platform } = addParams.getRequestParams(url);

  let token = profileStore.getSessionToken();
  let newUrl = url;

  if (options?.params) {
    const stringifyParams = queryString.stringify(options.params, { arrayFormat: 'bracket' });
    newUrl = `${url}?${stringifyParams}`;
  }

  const time = Date.now();

  const baseHeaders = {
    'x-trace-id': traceId,
    ...(source && { source }),
    ...(platform && { platform }),
  };

  const newOptions = {
    ...options,
    params: undefined,
    headers: { ...baseHeaders, ...options?.headers },
    credentials: 'omit',
    retry: 0,
    ...(!import.meta.server && {
      async onResponse() {
        const timeOut = Date.now() - time;

        if (timeOut > LOG_TIMEOUT) {
          console.warn(`Time-out: ${traceId}, ${timeOut}\n ${url}`);
        }
      },
    }),
    async onResponseError({ response }: any) {
      if (!import.meta.server && (response.status === 401 || response.status === 403)) {
        if (profileStore.isTelegramUser) {
          await profileStore.loginFromTelegramApp('onResponseError');
        } else {
          if (profileStore.getSessionToken()) await profileStore.removeSession();
          const { localizePath } = useProjectMethods();
          const router = useRouter();
          await router.push(localizePath('/?sign-in=true'));
        }
      }
    },
  };

  if (token) {
    newOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (token && profileStore.isTokenExpired()) {
    token = await profileStore.refreshToken();
    if (token) {
      newOptions.headers.Authorization = `Bearer ${token}`;
    } else {
      delete newOptions.headers.Authorization;
    }
  }

  try {
    const response = await $fetch(newUrl, newOptions);
    newOptions.onResponse = undefined;
    newOptions.onResponseError = undefined;
    return response;
  } catch (e: any) {
    if (!e.response) {
      console.warn(`No response: ${url}\n`, traceId);
    }
    throw e;
  }
};
