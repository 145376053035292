<template>
  <component
    :is="url ? AtomicLink : tagName"
    :class="componentClasses"
    class="btn"
    :disabled="isDisabled || null"
    :href="url"
    :targetBlank="targetBlank || null"
  >
    <atomic-btn-preloader v-if="isProcessing" />
    <slot />
  </component>
</template>

<script setup lang="ts">
  const {
    targetBlank = false,
    tagName = 'div',
    isDisabled = false,
    isActive = false,
    isProcessing = false,
    type,
    size,
  } = defineProps<{
    targetBlank?: boolean;
    tagName?: 'div' | 'span' | 'button';
    type?: 'primary' | 'secondary' | 'ghost' | 'gray' | 'green';
    size?: 'xs' | 'sm' | 'md';
    isDisabled?: boolean;
    isActive?: boolean;
    url?: string;
    isProcessing?: boolean;
  }>();
  const AtomicLink = resolveComponent('atomic-link');

  const componentClasses = computed(() => [
    type ? `btn-${type}` : '',
    size ? `size-${size}` : '',
    isActive ? 'is-active' : '',
  ]);
</script>

<style src="~/assets/styles/components/button/base.scss" lang="scss" />
